import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const TwitterInstantwinCampaignsResultsExplained = ({
    location
  }) => {
  const title =
    "インスタントウィン実績を大公開！Twitterキャンペーンには高額ギフトが必要？"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="インスタントウィン実績を大公開！Twitterキャンペーンには高額ギフトが必要？"
          pagedesc="販促キャンペーン実施にあたり悩みどころなのが、景品の金額と人数。賞品予算をどう配分すればプレゼント企画の効果を促進できるのか、実例を元に解説します。インスタントウィンを企画中の担当者が明日から使える情報満載です。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230715/230715-1.jpg"
          pagepath="/blog/twitter-instantwin-campaigns-results-explained"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は4分で読むことができます
            </div>
            <h1>
              インスタントウィン実績を大公開！Twitterキャンペーンには高額ギフトが必要？
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2023年7月15日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="インスタントウィン実績を大公開！Twitterキャンペーンには高額ギフトが必要？"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230715/230715-1.jpg"
                className="w-full"
              />
            </figure>
            <h2 id="id1">Twitterプレゼント企画を開催！でも…景品はいくら？当選は何人？</h2>
            <p>
              Twitterの企業アカウントでプレゼントキャンペーンを企画する際、「景品はいくらが良いのか？」は担当者が必ずと言っていいほど悩むポイントです。限られた景品予算をどう配分するかは、キャンペーンの成功度を大きく左右します。
            </p>

            <h2 id="id2">デジタルギフト券が当たるインスタントウィンで、情報発信を後押し！</h2>
            <p>
              この記事では、実際にPARKLoTが開催したTwitterインスタントウィンキャンペーンをもとに、「同じ景品予算の場合、当選人数を増やすべきか、それとも当選金額を増やすべきか？」を比較分析します。さらに、インプレッション、エンゲージメント総数、CPAなどの数値も公開。プレゼントキャンペーンを企画中の担当者必見です。
            </p>

            <p>この記事は次のような人にオススメです！</p>
            <ul className="blog__border-list">
              <li>インスタントウィンを企画中のマーケティング担当者</li>
              <li>販促キャンペーンを成功させたい企業</li>
              <li>過去キャンペーンの改善をして、フォロワーを伸ばしたい担当者</li>
            </ul>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    Twitterプレゼント企画を開催！でも…景品はいくら？当選は何人？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    デジタルギフト券が当たるインスタントウィンで、情報発信を後押し！
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    1. プレゼント企画のメリットとは？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        メリット1：見込み顧客に「見つけてもらえる」
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        メリット2：効率的にフォロワー獲得ができる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        メリット3：既存フォロワーとのコミュニケーションにつながる
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id7" className="toc__link">
                    2. キャンペーンの種類とおすすめは？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        その1 フォロー＆リツイートキャンペーン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        その2 【インスタントウィン】フォロー＆リツイートキャンペーン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        その3 ハッシュタグキャンペーン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        主要キャンペーン一覧表
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        【おすすめ】開設初期アカウントはインスタントウィン型フォロー＆リツイート！
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id13" className="toc__link">
                    3. キャンペーン景品の種類とおすすめは？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        デジタルギフト券
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id15" className="toc__link">
                        自社製品
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id16" className="toc__link">
                        その他（食品、人気家電など）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id17" className="toc__link">
                        【おすすめ】開設初期アカウントはデジタルギフト券！
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id18" className="toc__link">
                    4. インスタントウィンキャンペーンの実績を分析
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id19" className="toc__link">
                        インスタントウィンキャンペーン例の内容
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id20" className="toc__link">
                        結果：当選人数を優先したほうが反響大
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id21" className="toc__link">
                        注意点：PDCAを回して、自社の顧客に合わせていく必要あり
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id22" className="toc__link">
                    5. まとめ 賞品に迷ったら、金額よりも当選人数を優先しよう
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id23" className="toc__link">
                        当選人数が多い企画は、たくさんの応募が期待できる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id24" className="toc__link">
                        多数当選のインスタントウィンキャンペーンで、ブランド発信の足場を固めよう
                      </a>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id3">1. プレゼント企画のメリットとは？</h2>
              <p>
                まずは、Twitterキャンペーンでプレゼント企画を開催するメリットをまとめます。
              </p>

              <h3 id="id4">メリット1：見込み顧客に「見つけてもらえる」</h3>
              <p>
                「プレゼント企画はただのばらまき」と渋る上司がいるかもしれませんが、SNSでのプレゼントキャンペーンは、「サービス認知を広め、より多くの顧客と直接接点を持つ」のに非常に効果的です。
              </p>
              <p>
                それは、まずフォロワー数を増やして十分なオーディエンスを獲得し、その上で価値のあるコンテンツを発信することで、購買を促進したり顧客とのつながりを深めたりすることが可能になるからです。
              </p>
              <p>
                これは、釣りに例えると理解しやすいでしょう。魚がほとんどいない池での釣りは、どれだけ良い餌を用意しても魚が釣れるわけではありません。フォロワーが100人しかいないのに毎日の投稿に力を入れるのは、この餌を使う場所が間違っているのと同じです。
              </p>
              <p>
                つまり、多くのフォロワー、つまり"魚"を集める初めの一歩として、ツイートを拡散させていくキャンペーンの開催は非常に重要なものとなります。一度たくさんのフォロワーが集まれば、その後の魅力的なコンテンツ発信がより効果的になるからです。
              </p>
              <p>
                このように、適切な戦略でプレゼント企画を活用することは、決して無意味な"ばらまき"ではないのです。
              </p>

              <h3 id="id5">メリット2：効率的にフォロワー獲得ができる</h3>
              <p>
                フォロー＆リツイートに代表されるように参加が簡単なキャンペーンでは、多くのユーザーからの参加が期待できます。リツイートによりそのユーザーのフォロワー全体へブランド露出が広がり、その結果、また新たなフォロワー獲得の可能性が高まります。さらに、「広告感」を嫌うようになった最近のユーザーにとって、自分がフォローしている人がリツイートした情報は自然で説得力を持つものとなります。
              </p>
              <p>
                新規のフォロワー獲得の効率が高まり、次回のコンテンツ発信やキャンペーンがより多くのユーザーに届く土壌を整えることができる点も、プレゼント企画のメリットです。
              </p>

              <h3 id="id6">メリット3：既存フォロワーとのコミュニケーションにつながる</h3>
              <p>
                プレゼント企画を通じて感謝の意を示すことで、フォロワーのロイヤルティ向上や長期的な関係性の構築ができます。また、当選者が喜びの声をSNSでシェアするという口コミ効果も期待でき、新規のフォロワー獲得やブランドイメージの向上にも寄与します。さらに、プレゼントキャンペーンでは、企画次第ではフォロワーの興味やニーズを深く理解する機会にもなります。
              </p>
              <p>
                これらの結果として、既存フォロワーとの深いつながりが生まれ、効果的なブランドコミュニケーションを行うことができます。
              </p>
            </div>
            <div>
              <h2 id="id7">2. キャンペーンの種類とおすすめは？</h2>
              <p>
                Twitterキャンペーンには様々な種類がありますが、ここでは主要な3つをご紹介します。
              </p>
              
              <h3 id="id8">その1 フォロー＆リツイートキャンペーン</h3>
              <p>
                対象アカウントをフォロー後、対象投稿をリツイートするだけで応募が完了します。開催者は締切後に当選者を選定し、DMを送る必要があります。
              </p>

              <h3 id="id9">その2 【インスタントウィン】フォロー＆リツイートキャンペーン</h3>
              <p>
                こちらも、対象アカウントをフォロー後、対象投稿をリツイートするだけで応募が完了します。PARKLoTのような自動ツールを利用すると、ユーザーが応募するとその場で抽選スタート、当選者には自動でDMが送付されます。
              </p>

              <h3 id="id10">その3 ハッシュタグキャンペーン</h3>
              <p>
                対象アカウントをフォロー後、指定ハッシュタグをつけてツイートをすると応募が完了します。フォロー＆リツイートに比べると、応募の手間が少し増えます。しかし、その手間こそがブランドについて考える機会になるため、ユーザーとブランドとのつながりを深める大きなチャンスになるといわれています。
              </p>

              <h3 id="id11">主要キャンペーン一覧表</h3>
              <table>
                <thead>
                  <tr className="font-bold bg-palegrey">
                    <th>　</th>
                    <th>フォロー＆<br />RTキャンペーン</th>
                    <th>インスタントウィン<br />フォロー＆<br />RTキャンペーン</th>
                    <th>ハッシュタグ<br />キャンペーン</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold bg-palegrey">応募方法</td>
                    <td>①フォロー<br />②リツイート</td>
                    <td>①フォロー<br />②リツイート</td>
                    <td>①フォロー<br />②ハッシュタグ付きツイート</td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">応募方法</td>
                    <td>とても簡単</td>
                    <td>とても簡単</td>
                    <td>やや手間</td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">当選発表タイミング</td>
                    <td>締切後</td>
                    <td>応募してすぐ</td>
                    <td>締切後</td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">通知対象</td>
                    <td>当選者のみ</td>
                    <td>当選者のみ</td>
                    <td>当選者のみ</td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">通知方法</td>
                    <td>手動DM</td>
                    <td>自動DM</td>
                    <td>手動DM</td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">メリット</td>
                    <td>①顧客との出会い<br />②フォロワー獲得</td>
                    <td>①顧客との出会い<br />②フォロワー獲得</td>
                    <td>①顧客との出会い<br />③既存客との交流</td>
                  </tr>
                </tbody>
              </table>

              <h3 id="id12">【おすすめ】開設初期アカウントはインスタントウィン型フォロー＆リツイート！</h3>
              <p>
                前章で述べたキャンペーンのメリット3つをバランス良く向上させられるのが、<b>インスタントウィンフォロー＆リツイートキャンペーン</b>です。フォロワーを増やしたい開設初期のアカウントには、特にインスタントウィンの開催をおすすめします。なぜなら、最初に目指したい1万フォロワーに到達しやすいのが、インスタントウィンキャンペーンだからです。
              </p>
              <p>詳しくは、下記の記事を参照ください。</p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/cost-effectiveness-of-twitter-instant-win"
                    target="_blank"
                    rel="noreferrer"
                  >
                    上司を説得！Twitterインスタントウィンの費用対効果まとめ
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id13">3. キャンペーン景品の種類とおすすめは？</h2>
              <p>
                「よし、インスタントウィンのフォロー＆リツイートキャンペーンをやろう！」となったところで、どういった景品を用意すべきか迷うところですよね。
              </p>
              <p>キャンペーンの賞品としては、以下の3つがよく用いられます。</p>

              <h3 id="id14">デジタルギフト券</h3>
              <p>
                AmazonギフトカードやQUOカードPayのようなコード・URL形式のデジタルギフト券は、どんなユーザーにとっても魅力的な景品です。欲しいと思う人が多いということは、それだけたくさんの応募が期待できるということです。その結果、さらなる拡散にもつながります。デジタルギフト券は郵送の手間が省けるため、主催者にとっても管理が楽です。
              </p>
              <p>
                また、自社で発行するクーポンや割引コードも、同じく簡単に配布ができます。ただし、これらは既に購入を考えている人やファンにとって魅力的であるため、対象者がやや限定的になる点には注意が必要です。拡散よりもサイトでの利用（ = 売上向上）を優先する場合は、こちらを選択するとよいでしょう。
              </p>

              <h3 id="id15">自社製品</h3>
              <p>
                自社の製品を景品にする利点は、なんといってもそのプロモーション効果です。特に、フォロー＆リツイートキャンペーンでは対象投稿がどんどん拡散されていきます。その投稿のバナークリエイティブに商品の写真と紹介を載せておけば、販促効果が期待できます。当たった人の報告が他の人の参考になったりと、将来的に長い利点が見出せます。
              </p>
              <p>
                コアなファンが多いブランドでは、キャンペーン用グッズを作製すると、ファン垂涎のプレミア品としてたくさんの応募を集められます。
              </p>

              <h3 id="id16">その他（食品、人気家電など）</h3>
              <p>
                特別なお肉やお米の詰め合わせ、話題の人気家電など景品にするのも、注目を集めやすい方法です。この記事で触れているSNS上のキャンペーンはオープン懸賞のため、景品表示法の対象外となり、景品額は制限されません。（参考：消費者庁「
                  <a
                    href="https://www.caa.go.jp/policies/policy/representation/fair_labeling/premium_regulation/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    景品規制の概要
                  </a>
                、オープン懸賞」）
              </p>

              <h3 id="id17">【おすすめ】開設初期アカウントはデジタルギフト券！</h3>
              <p>
                ターゲットが広くて拡散しやすい、送付コストを抑えられるという理由から、解説初期は特にデジタルギフト券を景品にすることをおすすめします。どうしても自社製品を賞品にする必要がある場合は、A賞を自社製品、B賞をデジタルギフト券にするなど組み合わせて開催するとよいでしょう。
              </p>
              <p>
                デジタルギフト券で人気のAmazonギフトカードとQUOカードPayのどちらにすべきか迷う場合は、ぜひ以下の記事をご覧ください。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/amazon-gift-card-vs-quo-card-pay-for-sns-campaigns#id28"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【法人】AmazonギフトカードとQUOカードPay比較！キャンペーンで成果を出せるデジタルギフトは？
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id18">4. インスタントウィンキャンペーンの実績を分析</h2>
              <p>それでは、実例を使って当選金額と人数のどちらを優先すべきか分析してみましょう。</p>
              <p>純粋に景品額・当選人数で比較をするため、開催期間/バナー画像/応募方法など他の条件を揃えてキャンペーンを開催しました。</p>

              <h3 id="id19">インスタントウィンキャンペーン例の内容</h3>
              <ul>
                <li>
                  比較した点
                  <p>
                    バナーデザインはほぼ同じで、当選金額と人数が違う場合の応募状況を比較しました。
                  </p>
                </li>
                <li>
                  応募条件
                  <p>フォロー＆リツイート</p>
                </li>
                <li>
                  インスタントウィン
                  <p>PARKLoTのツールを使って、当選の場合は応募するとすぐにDMが届く設定にしました。</p>
                </li>
              </ul>

              <div className="blog__embedded-tweet">
                <div>
                  <p>・第2弾：金額<b>低め</b>・当選人数<b>多め</b></p>
                  <blockquote class="twitter-tweet">
                    <p lang="ja" dir="ltr">／<br />PARKLoT🎃<a href="https://twitter.com/hashtag/%E3%83%8F%E3%83%AD%E3%82%A6%E3%82%A3%E3%83%B3%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">#ハロウィンキャンペーン</a> <br />第2弾！<br />＼<a href="https://twitter.com/hashtag/%E3%83%95%E3%82%A9%E3%83%AD%E3%83%BCRT?src=hash&amp;ref_src=twsrc%5Etfw">#フォローRT</a> でその場で当たる！<a href="https://twitter.com/hashtag/Amazon%E3%82%AE%E3%83%95%E3%83%88%E5%88%B8?src=hash&amp;ref_src=twsrc%5Etfw">#Amazonギフト券</a> を100名様にプレゼント💫<br /><br />今すぐリツイートしてね！<br /><br />【応募方法】<br />1️⃣ <a href="https://twitter.com/PARKLoT1?ref_src=twsrc%5Etfw">@PARKLoT1</a> をフォロー<br />2️⃣この投稿をRT<br />📩当選者のみDM送付<br />〆切 10/24 <a href="https://t.co/CP6mnem3vE">pic.twitter.com/CP6mnem3vE</a>
                    </p>&mdash; 【公式】PARKLoT🎯インスタントウィンを最速で実施できるキャンペーンツール (@PARKLoT1) <a href="https://twitter.com/PARKLoT1/status/1582131857793372160?ref_src=twsrc%5Etfw">October 17, 2022</a>
                  </blockquote>
                </div>
                <div>
                  <p>・第3弾：金額<b>高め</b>・当選人数<b>少なめ</b></p>
                  <blockquote class="twitter-tweet">
                    <p lang="ja" dir="ltr">／<br />PARKLoT🎃<a href="https://twitter.com/hashtag/%E3%83%8F%E3%83%AD%E3%82%A6%E3%82%A3%E3%83%B3%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">#ハロウィンキャンペーン</a><br />第3弾！<br />＼<a href="https://twitter.com/hashtag/%E3%83%95%E3%82%A9%E3%83%AD%E3%83%BCRT?src=hash&amp;ref_src=twsrc%5Etfw">#フォローRT</a> でその場で当たる！<a href="https://twitter.com/hashtag/Amazon%E3%82%AE%E3%83%95%E3%83%88%E5%88%B8?src=hash&amp;ref_src=twsrc%5Etfw">#Amazonギフト券</a> 10,000円分👀をプレゼント💫<br /><br />今すぐリツイートしてね！<br /><br />【応募方法】<br />1️⃣ <a href="https://twitter.com/PARKLoT1?ref_src=twsrc%5Etfw">@PARKLoT1</a> をフォロー<br />2️⃣この投稿をRT🔁<br />📩DMで結果確認<br />〆切 10/31<br /><br />👇詳細/通知が来ない<a href="https://t.co/zQKvgaTi8U">https://t.co/zQKvgaTi8U</a> <a href="https://t.co/1vKZxvn9yz">pic.twitter.com/1vKZxvn9yz</a>
                    </p>&mdash; 【公式】PARKLoT🎯インスタントウィンを最速で実施できるキャンペーンツール (@PARKLoT1) <a href="https://twitter.com/PARKLoT1/status/1584668573523677184?ref_src=twsrc%5Etfw">October 24, 2022</a>
                  </blockquote>
                </div>
              </div>

              <h3 id="id20">結果：当選人数を優先したほうが反響大</h3>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="PARKLoTのインスタントウィンキャンペーンの比較"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230715/230715-2.png"
              />
              <p>
                その結果、なんと「リツイート数・参加者数・インプレッション数・エンゲージメント数・フォロワー増加数」と計測した全ての数値において、第2弾の「金額<b>低め</b>・当選人数<b>多め</b>」の方が良い実績となりました。
              </p>
              <ul>
                <li>
                  リツイート数は、第2弾がやや多い結果です。第2弾・第3弾共に、7日間のキャンペーン<b>4,000前後のリツイート</b>を記録しています。
                </li>
                <li>
                  インプレッション数は、第2弾が第3弾を約10,000も上回りました。キャンペーンツイートがより多くのユーザーの目に入ったということです。
                </li>
                <li>
                  エンゲージメント数も、第2弾が第3弾よりも約1,000多い結果です。第2弾・第3弾とも、<b>エンゲージメント率が17〜18％</b>と、一般的な目安よりも高くなりました。
                </li>
                <li>
                  第2弾では<b>フォロワーが800人増加</b>、第3弾で400人増加と、倍近くの差がつきました。これほど差が出るとは…予想以上でした。
                </li>
                <li>
                  CPA（CPF）は第2弾が12円、第3弾が23円と、<b>広告と比べて半分以下のコストでフォロワーを獲得</b>することができました。（目安：Twitter広告CPF100円の場合）
                </li>
              </ul>
              <p>
                以上の結果から、「総額が同じであれば、当選人数を増やした方がキャンペーンに効果的」と判断できました。
              </p>

              <h3 id="id21">注意点：PDCAを回して、自社の顧客に合わせていく必要あり</h3>
              <p>
                ただし、「当選人数を優先すべき」という考え方は、アカウントの特性や商材によっては適切でない場合もあります。実際に何度かキャンペーンを行い、どの種類のキャンペーンが最も反応が良いかを見つけることが重要です。予算が1万円の場合、1万円×1名にするか 100円×100名にするかだけではなく、5,000円×1名と100円×50名という組み合わせが最も効果的な場合もあるでしょう。
              </p>
            </div>
            <div>
              <h2 id="id22">5. まとめ 賞品に迷ったら、金額よりも当選人数を優先しよう</h2>

              <h3 id="id23">当選人数が多い企画は、たくさんの応募が期待できる</h3>
              <p>
                2つのキャンペーン結果から学べる点は、参加率をあげるには「可能な限り当選人数を増やすこと」が有効だということです。人間の心理から考えても、当選人数が多いと「自分も当たるかもしれない」と思いやすく、参加意欲が高まります。また、人数を多くするための単価として、100円〜でも効果が感じられる点も明らかになりました。
              </p>
              <p>
                初めてTwitterでインスタントウィン形式のキャンペーンをする場合は、以下を目安に設定すると良いでしょう。
              </p>
              <ul>
                <li>キャンペーン期間：3〜7日間</li>
                <li>賞品：デジタルギフト券100円〜1,000円</li>
                <li>
                  当選人数：10名〜100名（総額5,000円〜10,000円）
                  <p>
                    ※ Twitter APIベーシックで開催の場合は、1日の上限50名に注意
                  </p>
                </li>
              </ul>
              <p>
                キャンペーンの結果を見て振り返り、金額や人数を微調整しながら再度キャンペーンを実施することで、自社アカウントの顧客ユーザに喜んでもらえる内容にブラッシュアップすることも大切です。
              </p>
              
              <h3 id="id24">多数当選のインスタントウィンキャンペーンで、ブランド発信の足場を固めよう</h3>
              <p>
                多数の当選者へDMを送る、またはユーザが応募した直後に抽選結果を通知するといったことは、専用ツールがなければ難しい作業です。自動化ツールの利用はコストがかかりますが、その反面、フォロワーの獲得を早めることができ、無駄やミスを抑えることにもつながります。
              </p>
              <p>
                「このキャンペーン企画は伸びそう？」「ツールを使う場合はいくら必要？」など、少しでも疑問がある場合はぜひPARKLoTへお問合せください。
                <a
                  href="https://hub.park-lot.com/contact"
                  target="_blank"
                  rel="noreferrer"
                >メール
                </a>
                、
                <a
                  href="https://hub.park-lot.com/free-consulting"
                  target="_blank"
                  rel="noreferrer"
                >オンライン相談
                </a>
                も受け付けております。
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/amazon-gift-card-vs-quo-card-pay-for-sns-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【法人】AmazonギフトカードとQUOカードPay比較！キャンペーンで成果を出せるデジタルギフトは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/cost-effectiveness-of-twitter-instant-win"
                    target="_blank"
                    rel="noreferrer"
                  >
                    上司を説得！Twitterインスタントウィンの費用対効果まとめ
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/rise-engagement-rate-with-retweet-or-like-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    驚きのエンゲージメント率！リツイートいいねキャンペーンの魅力を徹底解説
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/campaign-for-food-and-restaurants"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【食品・飲食店特化】売上アップ！集客キャンペーン事例をわかりやすく解説
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-sns-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SNSプロモーションを成功させるには？成功事例から学べる4つのポイント
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-reduce-cost-of-sns-operations"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【Twitter企業アカウント】うまく運用代行を活用して業務コストを削減する方法とは？
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default TwitterInstantwinCampaignsResultsExplained
